import 'swiper/css';
import 'swiper/css/pagination';

import { useMemo, useState } from 'react';
import { FaAngleLeft } from '@react-icons/all-files/fa6/FaAngleLeft';
import { FaAngleRight } from '@react-icons/all-files/fa6/FaAngleRight';
import classNames from 'classnames';
import { Swiper } from 'swiper/react';
import { Swiper as SwiperType, VirtualOptions } from 'swiper/types';
import { v4 } from 'uuid';

import { useTheme } from 'context/theme';
import { useViewport } from 'context/viewport';
import carouselStyles from 'styles/Carousel.module.scss';
import styles from 'styles/ModelCarousel.module.scss';

import {
  CarouselProps,
  defaultArrowClassname,
  defaultPreRenderedVirtualSlidesAmount,
  defaultSwiperProps,
  swiperModules
} from './constants';

export const Carousel: React.FC<CarouselProps> = ({
  slidesPerView = 'auto',
  className,
  title,
  children,
  isDarkFromProps,
  isArrowsOutsideOfSlide,
  arrowClassname,
  swiperProps = {},
  virtual,
  disableArrows = false,
  showArrowGradient = true,
  showArrowBackground = false
}) => {
  const id = v4();
  const { isDark: isDarkFromTheme, isDefault } = useTheme();
  const { isMobile, isTabletOrMobile } = useViewport();

  const [swiper, setSwiper] = useState<SwiperType>();
  const isDark = isDarkFromTheme || isDarkFromProps;

  const showArrows = !disableArrows && !isTabletOrMobile;
  const arrowStyles = `px-2 align-self-center ${isDark ? 'text-light80' : 'text-primary'}`;

  if (swiper) {
    swiper.allowTouchMove = isTabletOrMobile;
  }

  const defaultSwiperVirtualOptions: VirtualOptions = useMemo(
    () => ({
      cache: true,
      ...(isMobile && {
        addSlidesAfter: defaultPreRenderedVirtualSlidesAmount,
        addSlidesBefore: defaultPreRenderedVirtualSlidesAmount
      })
    }),
    [isMobile]
  );

  return (
    <div className={className}>
      {title && (
        <div className={`fs-18px riforma-medium ${isDark ? 'text-light90' : 'text-primary'} px-4`}>{title}</div>
      )}
      <div className='d-flex align-items-center w-100 position-relative'>
        {showArrows && (
          <div
            className={classNames(`d-flex h-100 position-absolute opacity-100 image-swiper-button-prev-${id}`, {
              'w-5': showArrowGradient,
              // for some reason this class is not added initially on prev arrow
              'swiper-button-disabled': swiper?.isBeginning && !swiperProps.loop
            })}
            style={{ zIndex: '2', left: isArrowsOutsideOfSlide ? -40 : 0 }}
            role='button'
          >
            <div
              className={classNames('h-100 d-flex', defaultArrowClassname.left, arrowClassname?.left, {
                'bg-light': isDefault && showArrowBackground,
                'bg-darkTheme': isDark
              })}
            >
              <div className={arrowStyles}>
                <FaAngleLeft />
              </div>
            </div>
            {showArrowGradient && (
              <div
                className={classNames(
                  'h-100 w-100 position-relative',
                  isDefault && styles.gradientRight,
                  isDark && styles.darkGradientRight,
                  carouselStyles['gradient-right']
                )}
              />
            )}
          </div>
        )}
        <Swiper
          lazy
          virtual={virtual ? defaultSwiperVirtualOptions : undefined}
          {...defaultSwiperProps}
          {...swiperProps}
          navigation={
            showArrows
              ? {
                  prevEl: `.image-swiper-button-prev-${id}`,
                  nextEl: `.image-swiper-button-next-${id}`
                }
              : undefined
          }
          modules={swiperModules}
          slidesPerView={slidesPerView}
          onSwiper={setSwiper}
        >
          {children}
        </Swiper>
        {showArrows && (
          <div
            role='button'
            className={classNames('d-flex h-100 position-absolute opacity-100', `image-swiper-button-next-${id}`, {
              'w-5': showArrowGradient,
              // for some reason this class is not added initially on next arrow if there are no items to swipe
              'swiper-button-disabled': swiper?.isEnd && !swiperProps.loop
            })}
            style={{ zIndex: '2', right: isArrowsOutsideOfSlide ? -40 : 0 }}
          >
            {showArrowGradient && (
              <div
                className={classNames(
                  'h-100 w-100 position-relative',
                  isDefault && styles.gradientLeft,
                  isDark && styles.darkGradientLeft,
                  carouselStyles['gradient-left']
                )}
              />
            )}
            <div
              className={classNames('h-100 d-flex align-self-end', defaultArrowClassname.right, arrowClassname?.right, {
                'bg-light': isDefault && showArrowBackground,
                'bg-darkTheme': isDark
              })}
            >
              <div className={arrowStyles}>
                <FaAngleRight />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
