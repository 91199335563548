import { memo, ReactNode } from 'react';
import classNames from 'classnames';
import { SwiperProps, SwiperSlide } from 'swiper/react';

import { ContentLoader } from 'components/design-system/ContentLoader';
import { useTheme } from 'context/theme';
import { useViewport } from 'context/viewport';
import styles from 'styles/ModelCarousel.module.scss';
import { Model, StyledComponent } from 'types';

import { ArrowClassname, Carousel } from './Carousel';
import { ModelCard } from './ModelCard';

type ModelCarouselProps = {
  items: Model[];
  loading?: boolean;
  title: ReactNode;
} & StyledComponent;

const LOADING_HEIGHT_MOBILE = '300px';
const LOADING_HEIGHT_DESKTOP = '353px';
const placeHolders = Array.from(new Array(10)).map((_, i) => ({ id: i }));

const loadingArrowClassname: ArrowClassname = {
  left: 'end-0',
  right: 'start-0'
};

export const ModelCarousel: React.FC<ModelCarouselProps> = memo(({ title, items, className, loading }) => {
  const { isMobile } = useViewport();
  const { isDark } = useTheme();

  const arrowClassname: ArrowClassname = {
    left: isDark ? '' : 'bg-transparent',
    right: isDark ? '' : 'bg-transparent'
  };
  const models = items || placeHolders;

  const swiperProps: SwiperProps = {
    className: classNames('pt-3 pb-4 w-100', styles['carousel-swiper']),
    watchSlidesProgress: !isMobile
  };

  return (
    <Carousel
      virtual
      title={title}
      className={className}
      slidesPerView={isMobile ? 2 : 4}
      swiperProps={swiperProps}
      arrowClassname={loading ? loadingArrowClassname : arrowClassname}
      showArrowGradient={false}
    >
      {models.map((model, idx) => (
        <SwiperSlide key={`model-${idx}`} data-testid='swiper-slide' className={styles.modelSlide} virtualIndex={idx}>
          {loading ? (
            <ContentLoader borderRadius={20} height={isMobile ? LOADING_HEIGHT_MOBILE : LOADING_HEIGHT_DESKTOP} />
          ) : (
            <ModelCard
              model={model}
              className={`h-100 shadow-on-hover ${styles.shadowInTablet}`}
              imageSize='40vw'
              theme={isDark ? 'adjustable' : 'default'}
            />
          )}
        </SwiperSlide>
      ))}
    </Carousel>
  );
});
